@import "node_modules/encapsiadesign/less/encapsia.less";

@keyframes spin {
  from {transform: rotate(0deg)}
  to {transform: rotate(360deg)}
}

@-webkit-keyframes fadeOutUp {
  0%   { opacity: 1; }
  100% { opacity: 0; }
}
@-moz-keyframes fadeOutUp {
  0%   { opacity: 1; }
  100% { opacity: 0; }
}
@-o-keyframes fadeOutUp {
  0%   { opacity: 1; }
  100% { opacity: 0; }
}
@keyframes fadeOutUp {
  0%   { opacity: 1; }
  100% { opacity: 0;
  -webkit-transform: translate3d(0, -200%, 0);
    transform: translate3d(0, -200%, 0);
    }
}


* {
  box-sizing: border-box;
}

html {
  .encapsia-default-html;
}

body {
  .encapsia-default-body;
  display: flex;
  flex-direction: column;
  background-color: #303f54;
  color: white; /*#7E8385;*/
  font-size: 16px;
  line-height: 25px;
  letter-spacing:0.2px;
  font-weight: 100;

  /*
  transitions don't work with gradients yet, but the spec says
  they should.

  there appears to be an ugly hack, but it doesn't appear to
  support more than two gradients.
  */
  transition: background 0.3s;

  &[data-state='splash'] {
    .login-box {
      animation: fadeOutUp 1.3s forwards;
    }
    footer {
      display: none;
    }
  }

  &[data-state='error'] {
    .login-box section.error-message {
      display: block;
      background: rgba(239, 65, 53, 0.25);
      text-align: center;
      padding: 10px;
      border: #ef4135 2px solid;
      width: 100%;
      margin-bottom: 10px;
      .error-message-title {
        text-transform: uppercase;
        color: #ef4135;
      }
    }
  }

  &:not([data-state='error']) {
    .login-box .error-message {
      display: none;
    }
  }

  &:not([data-state='loading']) .login-box .loading-spinner {
    display: none;
  }

  &[data-state='loading'] {
    .login-box .providers {
        display: none;
    }
  }

  &:not([data-state='splash']) {
    .splash {
      display: none;
    }

  }
}

body > .container {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  color: white;
  overflow: auto;
  margin-top: 1%;
  margin-bottom: 5%;

  .login-box {
    position: inherit;
    right: 0;   
    left: 0;
    width: 400px;
    margin: auto;
    top: 20%;
    display: block;
    background: rgba(0,0,0,0.4); 
    padding: 20px;
    z-index: 2;
    border-radius: 20px;
    #helpdesk_btn,#email{
      color: white;
      font-style: italic;
    }
    header {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .container_sign_in {
        padding: 5px 40px;
      }
      h1, h3 {
        text-align: center;
        color: white;
      }
      h1 {
        font-weight: 700;
        text-transform: uppercase;
        font-size: 35px;
        margin-bottom: 0px;
      }
      h3 {
          font-weight: 100;
          margin-bottom: 0;
          margin-top: 0;
      }
      .logo-image{
        margin: 20px;
        width: 80%;
        margin-left: 30px;
      }

      svg {
        width: 15em;
        height: 15em;
      }
    }

    main {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;

      .providers {
        margin-top: 0;
        .has-error {
          border-color: #ef4135;
        }
        .clear-error {
          border-color: #fff;
        }
        button {
          display: block;
          margin-bottom: 1em;
          cursor: pointer;
          transition: all .3s;
          height: 40px;
          width: 170px;
          background: transparent;
          border: 2px solid white;
          border-radius: 20px;
          color: white;
          outline: none;
          &:hover {
            background-color: #ef4135;
            border-color: #ef4135;
          }
          span {
            text-align: center;
            margin-left: 20px;
          }
          i.zmdi.zmdi-hc-fw {
            float: right;
            margin: 2px 5px;
          }
        }
      }

      .loading-spinner {
      }

    }
  }

  .splash {
    .user-bar {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      background-color: #303f54;
      padding: 10px;
      height: 41px;
      z-index: 1; // Make appear over background image in IE

      .logout {
        margin-left: 1em;
        color: white;

        svg {
          width: 1em;
          height: 1em;
          fill: black;
        }
      }
    }
  }
}

footer {
    padding-top: 15px;
    padding-bottom: 6px;
    background: transparent;
    font-size: 12px;
    color: white;
    z-index: 1;
    position: fixed;
    bottom: 0;
    width: 100%;
    .footer-logo {
      display: block;
      position: fixed;
      bottom: 10px;
      right: 10px;
    }
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	color: black;
	font-weight: 100;
	letter-spacing:3px;
}

h3{
	font-size:18px;
}
h4{
	text-transform:uppercase;
	font-size:14px;
}
.light{
	font-weight:100;
}
hr {
	margin-top:25px;
	margin-bottom:25px;
  width: 100%;
  border: transparent;
  background-color: white;
  height: 1px;
}
p.copy {
  margin-right: 165px;
  margin-left: 10px;
  font-size: 12px;
  line-height: 1.423;
  a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    &:hover {
      text-decoration: underline;    
    }
  }
  &:hover {
    color: white;
  }
}

.helpdesk {
  transition: height 0.3s ease;
  overflow: auto;
  .inner_text{
    height: 100%;

  }
}

.large-image-background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  border: none;
  padding: 0;
  margin: 0;
  background-image: none;
  &.image-loaded {
    background-image: url('../img/encapsia-background-with-pattern.jpg');
    transition: all 0.5s ease 0s;
    opacity: 1;
    z-index:0;
  }
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0);
  background-size: cover;
  background-position: 50% 50%;
  opacity: 0;
}
@container: .encapsia-default-html;
.setAppTheme(#303f54, @container);

