/* THEME NAMES */
/*
Use one of this theme names when applying the theme to the app.
To apply a theme call .setAppTheme(@encapsia-theme-name, @container) in your main less file.
*/
/**************

      COLORS

**************/
/* THEME NAMES */
/*
Use one of this theme names when applying the theme to the app.
To apply a theme call .setAppTheme(@encapsia-theme-name, @container) in your main less file.
*/
/* LAYOUT COLORS */
/* BUTTON COLORS */
/* FOOTER COLORS */
/***************************

      FONTS

****************************/
/* TEXT FONTS */
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Regular-webfont.eot');
  src: url('../fonts/Roboto-Regular-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/Roboto-Regular-webfont.woff') format('woff'), url('../fonts/Roboto-Regular-webfont.ttf') format('truetype'), url('../fonts/Roboto-Regular-webfont.svg#RobotoRegular') format('svg');
  font-weight: 400;
  font-style: normal;
}
.encapsia-default-font {
  font-family: 'Roboto', Arial, sans-serif;
  color: #303f54;
  font-size: 14px;
}
/* ICON FONTS */
/*************

    SIZES

**************/
/* BODY STANDARDS */
/* HEADER */
/* FOOTER */
/* RIGHT SIDE */
/* LEFT SIDE */
.encapsia-header {
  border-bottom: 1px solid #e9edee;
}
/* UTILITY FUNCTIONS */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes fadeOutUp {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes fadeOutUp {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOutUp {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -200%, 0);
    transform: translate3d(0, -200%, 0);
  }
}
* {
  box-sizing: border-box;
}
html {
  height: 100%;
  -ms-overflow-style: scrollbar;
  margin: 0;
  padding: 0;
}
html h1,
html h2,
html h3,
html h4,
html h5,
html h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: 'Roboto', Arial, sans-serif;
  color: #303f54;
}
html .btn-default {
  color: #303f54;
  background-color: #FFFFFF;
  border-color: #d9d9d9;
  box-shadow: none;
}
html .btn-default:hover,
html .btn-default:focus {
  background-color: #e6e6e6;
  border-color: #cccccc;
  box-shadow: none;
}
html .btn-primary {
  background-color: #2196f3;
  border-color: #2196f3;
  box-shadow: none;
}
html .btn-primary:hover,
html .btn-primary:focus {
  background-color: #0c7cd5;
  border-color: #0a6ebd;
  box-shadow: none;
}
html .btn-success {
  box-shadow: none;
  background-color: #4CB050;
  border-color: #4CB050;
}
html .btn-success:hover,
html .btn-success:focus {
  background-color: #3d8c40;
  border-color: #357b38;
  box-shadow: none;
}
html .btn-info {
  box-shadow: none;
  background-color: #29B6F6;
  border-color: #29B6F6;
}
html .btn-info:hover,
html .btn-info:focus {
  background-color: #0a9fe2;
  border-color: #088eca;
  box-shadow: none;
}
html .btn-warning {
  box-shadow: none;
  background-color: #FF9800;
  border-color: #FF9800;
}
html .btn-warning:hover,
html .btn-warning:focus {
  background-color: #cc7a00;
  border-color: #b36a00;
  box-shadow: none;
}
html .btn-danger {
  box-shadow: none;
  background-color: #ef4135;
  border-color: #ef4135;
}
html .btn-danger:hover,
html .btn-danger:focus {
  background-color: #df1f12;
  border-color: #c81c10;
  box-shadow: none;
}
html .btn-warning,
html .btn-success,
html .btn-info,
html .btn-danger,
html .btn-primary {
  color: #FFFFFF;
}
html .text-primary {
  color: #2196f3;
}
html .text-success {
  color: #4CB050;
}
html .text-info {
  color: #29B6F6;
}
html .text-danger {
  color: #ef4135;
}
html .text-warning {
  color: #FF9800;
}
html .text-muted {
  color: #888c92;
}
html .label-primary {
  background-color: #2196f3;
}
html .label-success {
  background-color: #4CB050;
}
html .label-info {
  background-color: #29B6F6;
}
html .label-danger {
  background-color: #ef4135;
}
html .label-warning {
  background-color: #FF9800;
}
html .label-default {
  background-color: #888c92;
}
html .alert-success {
  color: #4CB050;
  background-color: #e4f3e4;
  border: 2px solid #4CB050;
}
html .alert-info {
  color: #29B6F6;
  background-color: #def4fd;
  border: 2px solid #29B6F6;
}
html .alert-danger {
  color: #ef4135;
  background-color: #fce2e0;
  border: 2px solid #ef4135;
}
html .alert-warning {
  color: #FF9800;
  background-color: #ffefd8;
  border: 2px solid #FF9800;
}
html .bg-success,
html .success {
  background-color: #dff1e0;
}
html .bg-info,
html .info {
  background-color: #d3eafd;
}
html .bg-danger,
html .danger {
  background-color: #fbe3e1;
}
html .bg-warning,
html .warning {
  background-color: #ffeacd;
}
html .panel-primary,
html .panel-info {
  background-color: #d3eafd;
}
html .panel-primary .panel-heading,
html .panel-info .panel-heading,
html .panel-primary .panel-footer,
html .panel-info .panel-footer {
  background-color: #d3eafd;
}
html .panel-success {
  background-color: #dff1e0;
}
html .panel-success .panel-heading,
html .panel-success .panel-footer {
  background-color: #dff1e0;
}
html .panel-warning {
  background-color: #ffeacd;
}
html .panel-warning .panel-heading,
html .panel-warning .panel-footer {
  background-color: #ffeacd;
}
html .panel-danger {
  background-color: #fbe3e1;
}
html .panel-danger .panel-heading,
html .panel-danger .panel-footer {
  background-color: #fbe3e1;
}
body {
  font-family: 'Roboto', Arial, sans-serif;
  color: #303f54;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  line-height: 1.4285;
  text-rendering: optimizeLegibility;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  background-color: #303f54;
  color: white;
  /*#7E8385;*/
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.2px;
  font-weight: 100;
  /*
  transitions don't work with gradients yet, but the spec says
  they should.

  there appears to be an ugly hack, but it doesn't appear to
  support more than two gradients.
  */
  transition: background 0.3s;
}
body[data-state='splash'] .login-box {
  animation: fadeOutUp 1.3s forwards;
}
body[data-state='splash'] footer {
  display: none;
}
body[data-state='error'] .login-box section.error-message {
  display: block;
  background: rgba(239, 65, 53, 0.25);
  text-align: center;
  padding: 10px;
  border: #ef4135 2px solid;
  width: 100%;
  margin-bottom: 10px;
}
body[data-state='error'] .login-box section.error-message .error-message-title {
  text-transform: uppercase;
  color: #ef4135;
}
body:not([data-state='error']) .login-box .error-message {
  display: none;
}
body:not([data-state='loading']) .login-box .loading-spinner {
  display: none;
}
body[data-state='loading'] .login-box .providers {
  display: none;
}
body:not([data-state='splash']) .splash {
  display: none;
}
body > .container {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  color: white;
  overflow: auto;
  margin-top: 1%;
  margin-bottom: 5%;
}
body > .container .login-box {
  position: inherit;
  right: 0;
  left: 0;
  width: 400px;
  margin: auto;
  top: 20%;
  display: block;
  background: rgba(0, 0, 0, 0.4);
  padding: 20px;
  z-index: 2;
  border-radius: 20px;
}
body > .container .login-box #helpdesk_btn,
body > .container .login-box #email {
  color: white;
  font-style: italic;
}
body > .container .login-box header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
body > .container .login-box header .container_sign_in {
  padding: 5px 40px;
}
body > .container .login-box header h1,
body > .container .login-box header h3 {
  text-align: center;
  color: white;
}
body > .container .login-box header h1 {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 35px;
  margin-bottom: 0px;
}
body > .container .login-box header h3 {
  font-weight: 100;
  margin-bottom: 0;
  margin-top: 0;
}
body > .container .login-box header .logo-image {
  margin: 20px;
  width: 80%;
  margin-left: 30px;
}
body > .container .login-box header svg {
  width: 15em;
  height: 15em;
}
body > .container .login-box main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}
body > .container .login-box main .providers {
  margin-top: 0;
}
body > .container .login-box main .providers .has-error {
  border-color: #ef4135;
}
body > .container .login-box main .providers .clear-error {
  border-color: #fff;
}
body > .container .login-box main .providers button {
  display: block;
  margin-bottom: 1em;
  cursor: pointer;
  transition: all 0.3s;
  height: 40px;
  width: 170px;
  background: transparent;
  border: 2px solid white;
  border-radius: 20px;
  color: white;
  outline: none;
}
body > .container .login-box main .providers button:hover {
  background-color: #ef4135;
  border-color: #ef4135;
}
body > .container .login-box main .providers button span {
  text-align: center;
  margin-left: 20px;
}
body > .container .login-box main .providers button i.zmdi.zmdi-hc-fw {
  float: right;
  margin: 2px 5px;
}
body > .container .splash .user-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background-color: #303f54;
  padding: 10px;
  height: 41px;
  z-index: 1;
}
body > .container .splash .user-bar .logout {
  margin-left: 1em;
  color: white;
}
body > .container .splash .user-bar .logout svg {
  width: 1em;
  height: 1em;
  fill: black;
}
footer {
  padding-top: 15px;
  padding-bottom: 6px;
  background: transparent;
  font-size: 12px;
  color: white;
  z-index: 1;
  position: fixed;
  bottom: 0;
  width: 100%;
}
footer .footer-logo {
  display: block;
  position: fixed;
  bottom: 10px;
  right: 10px;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: black;
  font-weight: 100;
  letter-spacing: 3px;
}
h3 {
  font-size: 18px;
}
h4 {
  text-transform: uppercase;
  font-size: 14px;
}
.light {
  font-weight: 100;
}
hr {
  margin-top: 25px;
  margin-bottom: 25px;
  width: 100%;
  border: transparent;
  background-color: white;
  height: 1px;
}
p.copy {
  margin-right: 165px;
  margin-left: 10px;
  font-size: 12px;
  line-height: 1.423;
}
p.copy a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}
p.copy a:hover {
  text-decoration: underline;
}
p.copy:hover {
  color: white;
}
.helpdesk {
  transition: height 0.3s ease;
  overflow: auto;
}
.helpdesk .inner_text {
  height: 100%;
}
.large-image-background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  border: none;
  padding: 0;
  margin: 0;
  background-image: none;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0);
  background-size: cover;
  background-position: 50% 50%;
  opacity: 0;
}
.large-image-background.image-loaded {
  background-image: url('../img/encapsia-background-with-pattern.jpg');
  transition: all 0.5s ease 0s;
  opacity: 1;
  z-index: 0;
}
.encapsia-default-html {
  height: 100%;
  -ms-overflow-style: scrollbar;
  margin: 0;
  padding: 0;
}
.encapsia-default-html h1,
.encapsia-default-html h2,
.encapsia-default-html h3,
.encapsia-default-html h4,
.encapsia-default-html h5,
.encapsia-default-html h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: 'Roboto', Arial, sans-serif;
  color: #303f54;
}
.encapsia-default-html .btn-default {
  color: #303f54;
  background-color: #FFFFFF;
  border-color: #d9d9d9;
  box-shadow: none;
}
.encapsia-default-html .btn-default:hover,
.encapsia-default-html .btn-default:focus {
  background-color: #e6e6e6;
  border-color: #cccccc;
  box-shadow: none;
}
.encapsia-default-html .btn-primary {
  background-color: #2196f3;
  border-color: #2196f3;
  box-shadow: none;
}
.encapsia-default-html .btn-primary:hover,
.encapsia-default-html .btn-primary:focus {
  background-color: #0c7cd5;
  border-color: #0a6ebd;
  box-shadow: none;
}
.encapsia-default-html .btn-success {
  box-shadow: none;
  background-color: #4CB050;
  border-color: #4CB050;
}
.encapsia-default-html .btn-success:hover,
.encapsia-default-html .btn-success:focus {
  background-color: #3d8c40;
  border-color: #357b38;
  box-shadow: none;
}
.encapsia-default-html .btn-info {
  box-shadow: none;
  background-color: #29B6F6;
  border-color: #29B6F6;
}
.encapsia-default-html .btn-info:hover,
.encapsia-default-html .btn-info:focus {
  background-color: #0a9fe2;
  border-color: #088eca;
  box-shadow: none;
}
.encapsia-default-html .btn-warning {
  box-shadow: none;
  background-color: #FF9800;
  border-color: #FF9800;
}
.encapsia-default-html .btn-warning:hover,
.encapsia-default-html .btn-warning:focus {
  background-color: #cc7a00;
  border-color: #b36a00;
  box-shadow: none;
}
.encapsia-default-html .btn-danger {
  box-shadow: none;
  background-color: #ef4135;
  border-color: #ef4135;
}
.encapsia-default-html .btn-danger:hover,
.encapsia-default-html .btn-danger:focus {
  background-color: #df1f12;
  border-color: #c81c10;
  box-shadow: none;
}
.encapsia-default-html .btn-warning,
.encapsia-default-html .btn-success,
.encapsia-default-html .btn-info,
.encapsia-default-html .btn-danger,
.encapsia-default-html .btn-primary {
  color: #FFFFFF;
}
.encapsia-default-html .text-primary {
  color: #2196f3;
}
.encapsia-default-html .text-success {
  color: #4CB050;
}
.encapsia-default-html .text-info {
  color: #29B6F6;
}
.encapsia-default-html .text-danger {
  color: #ef4135;
}
.encapsia-default-html .text-warning {
  color: #FF9800;
}
.encapsia-default-html .text-muted {
  color: #888c92;
}
.encapsia-default-html .label-primary {
  background-color: #2196f3;
}
.encapsia-default-html .label-success {
  background-color: #4CB050;
}
.encapsia-default-html .label-info {
  background-color: #29B6F6;
}
.encapsia-default-html .label-danger {
  background-color: #ef4135;
}
.encapsia-default-html .label-warning {
  background-color: #FF9800;
}
.encapsia-default-html .label-default {
  background-color: #888c92;
}
.encapsia-default-html .alert-success {
  color: #4CB050;
  background-color: #e4f3e4;
  border: 2px solid #4CB050;
}
.encapsia-default-html .alert-info {
  color: #29B6F6;
  background-color: #def4fd;
  border: 2px solid #29B6F6;
}
.encapsia-default-html .alert-danger {
  color: #ef4135;
  background-color: #fce2e0;
  border: 2px solid #ef4135;
}
.encapsia-default-html .alert-warning {
  color: #FF9800;
  background-color: #ffefd8;
  border: 2px solid #FF9800;
}
.encapsia-default-html .bg-success,
.encapsia-default-html .success {
  background-color: #dff1e0;
}
.encapsia-default-html .bg-info,
.encapsia-default-html .info {
  background-color: #d3eafd;
}
.encapsia-default-html .bg-danger,
.encapsia-default-html .danger {
  background-color: #fbe3e1;
}
.encapsia-default-html .bg-warning,
.encapsia-default-html .warning {
  background-color: #ffeacd;
}
.encapsia-default-html .panel-primary,
.encapsia-default-html .panel-info {
  background-color: #d3eafd;
}
.encapsia-default-html .panel-primary .panel-heading,
.encapsia-default-html .panel-info .panel-heading,
.encapsia-default-html .panel-primary .panel-footer,
.encapsia-default-html .panel-info .panel-footer {
  background-color: #d3eafd;
}
.encapsia-default-html .panel-success {
  background-color: #dff1e0;
}
.encapsia-default-html .panel-success .panel-heading,
.encapsia-default-html .panel-success .panel-footer {
  background-color: #dff1e0;
}
.encapsia-default-html .panel-warning {
  background-color: #ffeacd;
}
.encapsia-default-html .panel-warning .panel-heading,
.encapsia-default-html .panel-warning .panel-footer {
  background-color: #ffeacd;
}
.encapsia-default-html .panel-danger {
  background-color: #fbe3e1;
}
.encapsia-default-html .panel-danger .panel-heading,
.encapsia-default-html .panel-danger .panel-footer {
  background-color: #fbe3e1;
}
.encapsia-default-body {
  font-family: 'Roboto', Arial, sans-serif;
  color: #303f54;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  line-height: 1.4285;
  text-rendering: optimizeLegibility;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.encapsia-default-thead {
  background-color: #e9edee;
  color: #303f54;
}
.encapsia-default-tfoot {
  background-color: #e9edee;
  color: #303f54;
}
html {
  height: 100%;
  -ms-overflow-style: scrollbar;
  margin: 0;
  padding: 0;
}
html h1,
html h2,
html h3,
html h4,
html h5,
html h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: 'Roboto', Arial, sans-serif;
  color: #303f54;
}
html .btn-default {
  color: #303f54;
  background-color: #FFFFFF;
  border-color: #d9d9d9;
  box-shadow: none;
}
html .btn-default:hover,
html .btn-default:focus {
  background-color: #e6e6e6;
  border-color: #cccccc;
  box-shadow: none;
}
html .btn-primary {
  background-color: #2196f3;
  border-color: #2196f3;
  box-shadow: none;
}
html .btn-primary:hover,
html .btn-primary:focus {
  background-color: #0c7cd5;
  border-color: #0a6ebd;
  box-shadow: none;
}
html .btn-success {
  box-shadow: none;
  background-color: #4CB050;
  border-color: #4CB050;
}
html .btn-success:hover,
html .btn-success:focus {
  background-color: #3d8c40;
  border-color: #357b38;
  box-shadow: none;
}
html .btn-info {
  box-shadow: none;
  background-color: #29B6F6;
  border-color: #29B6F6;
}
html .btn-info:hover,
html .btn-info:focus {
  background-color: #0a9fe2;
  border-color: #088eca;
  box-shadow: none;
}
html .btn-warning {
  box-shadow: none;
  background-color: #FF9800;
  border-color: #FF9800;
}
html .btn-warning:hover,
html .btn-warning:focus {
  background-color: #cc7a00;
  border-color: #b36a00;
  box-shadow: none;
}
html .btn-danger {
  box-shadow: none;
  background-color: #ef4135;
  border-color: #ef4135;
}
html .btn-danger:hover,
html .btn-danger:focus {
  background-color: #df1f12;
  border-color: #c81c10;
  box-shadow: none;
}
html .btn-warning,
html .btn-success,
html .btn-info,
html .btn-danger,
html .btn-primary {
  color: #FFFFFF;
}
html .text-primary {
  color: #2196f3;
}
html .text-success {
  color: #4CB050;
}
html .text-info {
  color: #29B6F6;
}
html .text-danger {
  color: #ef4135;
}
html .text-warning {
  color: #FF9800;
}
html .text-muted {
  color: #888c92;
}
html .label-primary {
  background-color: #2196f3;
}
html .label-success {
  background-color: #4CB050;
}
html .label-info {
  background-color: #29B6F6;
}
html .label-danger {
  background-color: #ef4135;
}
html .label-warning {
  background-color: #FF9800;
}
html .label-default {
  background-color: #888c92;
}
html .alert-success {
  color: #4CB050;
  background-color: #e4f3e4;
  border: 2px solid #4CB050;
}
html .alert-info {
  color: #29B6F6;
  background-color: #def4fd;
  border: 2px solid #29B6F6;
}
html .alert-danger {
  color: #ef4135;
  background-color: #fce2e0;
  border: 2px solid #ef4135;
}
html .alert-warning {
  color: #FF9800;
  background-color: #ffefd8;
  border: 2px solid #FF9800;
}
html .bg-success,
html .success {
  background-color: #dff1e0;
}
html .bg-info,
html .info {
  background-color: #d3eafd;
}
html .bg-danger,
html .danger {
  background-color: #fbe3e1;
}
html .bg-warning,
html .warning {
  background-color: #ffeacd;
}
html .panel-primary,
html .panel-info {
  background-color: #d3eafd;
}
html .panel-primary .panel-heading,
html .panel-info .panel-heading,
html .panel-primary .panel-footer,
html .panel-info .panel-footer {
  background-color: #d3eafd;
}
html .panel-success {
  background-color: #dff1e0;
}
html .panel-success .panel-heading,
html .panel-success .panel-footer {
  background-color: #dff1e0;
}
html .panel-warning {
  background-color: #ffeacd;
}
html .panel-warning .panel-heading,
html .panel-warning .panel-footer {
  background-color: #ffeacd;
}
html .panel-danger {
  background-color: #fbe3e1;
}
html .panel-danger .panel-heading,
html .panel-danger .panel-footer {
  background-color: #fbe3e1;
}
body {
  font-family: 'Roboto', Arial, sans-serif;
  color: #303f54;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  line-height: 1.4285;
  text-rendering: optimizeLegibility;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.encapsia-default-html .encapsia-navbar-user-dropdown {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
  margin-right: 0;
  cursor: pointer;
}
.encapsia-default-html .encapsia-navbar-user-dropdown a {
  color: white;
  text-decoration: none;
  text-align: right;
  padding: 0 10px;
}
.encapsia-default-html .encapsia-navbar-user-dropdown a .trial-label {
  padding-right: 24px;
}
.encapsia-default-html .encapsia-navbar-user-dropdown ul.dropdown-menu {
  display: none;
}
.encapsia-default-html .encapsia-navbar-user-dropdown.open ul.dropdown-menu {
  display: block;
  position: absolute;
  top: 41px;
  right: 0px;
  min-width: 200px;
  padding: 0;
  border-radius: 0;
  z-index: 2003;
  margin: 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: white;
  background-clip: padding-box;
  border: none;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.encapsia-default-html .encapsia-navbar-user-dropdown.open ul.dropdown-menu a {
  color: #303f54;
}
.encapsia-default-html .encapsia-navbar-user-dropdown.open ul.dropdown-menu li {
  height: 41px;
  line-height: 41px;
}
.encapsia-default-html .encapsia-navbar-user-dropdown.open ul.dropdown-menu li a {
  text-align: left;
  padding: 0 10px;
  outline: 0;
  background: transparent;
  line-height: 41px;
  border: 0;
  box-shadow: none;
  border-radius: 0;
  cursor: pointer;
}
.encapsia-default-html .encapsia-navbar-user-dropdown.open ul.dropdown-menu li a span {
  padding-left: 8px;
}
.encapsia-default-html .encapsia-navbar-user-dropdown.open ul.dropdown-menu li:focus,
.encapsia-default-html .encapsia-navbar-user-dropdown.open ul.dropdown-menu li:hover {
  background-color: #e9edee;
}
.encapsia-default-html::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.encapsia-default-html .encapsia-scroller:hover::-webkit-scrollbar-thumb,
.encapsia-default-html::-webkit-scrollbar-thumb:horizontal:hover,
.encapsia-default-html::-webkit-scrollbar-thumb:vertical:hover,
.encapsia-default-html::-webkit-scrollbar-thumb:horizontal:active,
.encapsia-default-html::-webkit-scrollbar-thumb:vertical:active {
  background: #303f54;
}
.encapsia-default-html::-webkit-scrollbar-track,
.encapsia-default-html::-webkit-scrollbar-thumb {
  border-radius: 50px;
}
/* BORDER */
.encapsia-default-html .encapsia-scroll-scheme-light::-webkit-scrollbar-track,
.encapsia-default-html .encapsia-scroll-scheme-light::-webkit-scrollbar-thumb {
  border: 1px solid #e9edee;
}
.encapsia-default-html .encapsia-scroll-scheme-dark::-webkit-scrollbar-track,
.encapsia-default-html .encapsia-scroll-scheme-dark::-webkit-scrollbar-thumb {
  border: 1px solid #243142;
}
/* TRACK */
.encapsia-default-html .encapsia-scroll-scheme-light::-webkit-scrollbar-track {
  background: white;
}
.encapsia-default-html .encapsia-scroll-scheme-dark::-webkit-scrollbar-track {
  background: transparent;
}
/* THUMB */
.encapsia-default-html .encapsia-scroll-scheme-light::-webkit-scrollbar-thumb,
.encapsia-default-html .encapsia-scroll-scheme-dark::-webkit-scrollbar-thumb {
  background: #d6d8dd;
}
.encapsia-default-html .encapsia-navbar-brand {
  width: 250px;
}
.encapsia-default-html .encapsia-navbar {
  width: 100%;
  height: 41px;
  background-color: #303f54;
  min-height: 41px;
  position: fixed;
  top: 0;
  border-radius: 0;
}
.encapsia-default-html .encapsia-navbar a {
  color: white;
  display: inline-block;
  outline: 0;
}
.encapsia-default-html .encapsia-navbar .encapsia-navbar-app-logo {
  width: 50px;
  height: 40px;
  display: block;
  background: url('../img/encapsia-icon.svg');
  background-size: 41px;
  background-repeat: no-repeat;
  background-position: 50%;
  padding: 0;
  margin: 0;
  border: 0;
  float: left;
  display: inline-block;
}
.encapsia-default-html .encapsia-navbar .encapsia-default-html .encapsia-navbar-app-title {
  line-height: 41px;
  vertical-align: middle;
  color: #FFFFFF;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: bold;
  float: left;
  display: inline-block;
}
